import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import UserInformationForm from './chatComps/UserInformationForm';
import ConsentScreen from './chatComps/ConsentScreen';
import ChatInterface from './chatComps/ChatInterface';
import FinalScreen from './chatComps/FinalScreen';
import LogoBlack from '../icons/logoBlack';

function ChatSession() {
    const { interviewId } = useParams();
    const history = useHistory();
    const [sessionId, setSessionId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [chatStarted, setChatStarted] = useState(false);
    const [interviewComplete, setInterviewComplete] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const [userInfoCollected, setUserInfoCollected] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(false);
    const [fakeLoading, setFakeLoading] = useState(true);
    const [showFakeWelcome, setShowFakeWelcome] = useState(false);
    const [organization, setOrganization] = useState('');

    useEffect(() => {
        const fetchInterviewDetails = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}`);
                setUserInfo({ ...response.data });
            } catch (error) {
                console.error('Failed to fetch interview details:', error);
                setError('Failed to fetch interview details');
            }
        };

        if (consentGiven && userInfoCollected) {
            fetchInterviewDetails();
        }
    }, [interviewId, consentGiven, userInfoCollected]);

    useEffect(() => {
        const fetchOrganizationName = async () => {
            try {
                const response = await axios.get(`/api/interviews/${interviewId}/details`);
                const { organizationName } = response.data;
                setOrganization(organizationName);
            } catch (error) {
                console.error('Failed to fetch organization name:', error);
                setError('Failed to fetch organization name');
            }
        };

        fetchOrganizationName();
    }, [interviewId]);

    const handleUserInformationSubmit = (userInfo) => {
        setUserInfo(userInfo);
        setUserInfoCollected(true);
        startChatSession(userInfo);
    };

    const handleBeginChat = () => {
        if (isConsentChecked) {
            setConsentGiven(true);
        }
    };

    const startChatSession = async (userInfo) => {
        setFakeLoading(true);

        setTimeout(() => {
            setFakeLoading(false);
            setShowFakeWelcome(true);
            setMessages([{ text: "Welcome! I'm here to assist you. Let's get started while I prepare the questions...", sender: 'bot' }]);
        }, 1500);

        setTimeout(async () => {
            try {
                setLoading(true);

                const response = await axios.post(`/api/sessions/${interviewId}`, { consentGiven, userInfo });
                const { sessionId, initialMessage, preFormId, postFormId } = response.data;
                setSessionId(sessionId);

                setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: initialMessage || 'No initial message', sender: 'bot' }
                ]);

                setLoading(false);
                setChatStarted(true);
            } catch (error) {
                console.error('Failed to start chat session:', error);
                setError('Failed to start chat session');
                setLoading(false);
            }
        }, 3000);
    };

    const sendMessage = async (messageText) => {
        if (!sessionId) {
            setError('Session ID is null. Cannot send message.');
            return;
        }

        setLoading(true);

        setMessages((prevMessages) => [
            ...prevMessages,
            { text: messageText, sender: 'user' }
        ]);

        try {
            const response = await axios.post(`/api/sessions/${interviewId}/${sessionId}/send`, { message: messageText });
            const botResponse = response.data.botResponse;
            
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: botResponse, sender: 'bot' }
            ]);

        } catch (error) {
            console.error('Failed to send message:', error);
            setError('Failed to send message');
        } finally {
            setLoading(false);
            setInputText('');
        }
    };

    const completeInterview = async () => {
        if (!sessionId) {
            setError('No session to complete.');
            return;
        }
        try {
            const response = await axios.post(`/api/complete/${interviewId}`, { sessionId, messages });
            if (response.status === 200) {
                setInterviewComplete(true);
                history.push('/thank-you');
            } else {
                setError('Failed to complete interview.');
            }
        } catch (error) {
            console.error('Failed to complete interview:', error);
            setError('Failed to complete interview');
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!consentGiven) {
        return (
            <div className="chat-interface container mx-auto p-4 md:w-[37rem] sm:w-full">
                <div className=''>
                    <LogoBlack />
                </div>
                <ConsentScreen 
                    isConsentChecked={isConsentChecked}
                    setIsConsentChecked={setIsConsentChecked}
                    handleBeginChat={handleBeginChat}
                    organization={organization}
                />
            </div>
        );
    }

    if (consentGiven && !userInfoCollected) {
        return (
            <div className="chat-interface container mx-auto p-4 md:w-[37rem] sm:w-full">
                <div className=''>
                    <LogoBlack />
                </div>
                <UserInformationForm onSubmit={handleUserInformationSubmit} />
            </div>
        );
    }

    if (interviewComplete) {
        return <FinalScreen />;
    }

    return (
        <ChatInterface
            messages={messages}
            inputText={inputText}
            setInputText={setInputText}
            sendMessage={sendMessage}
            completeInterview={completeInterview}
            loading={loading || fakeLoading}
        />
    );
}

export default ChatSession;
