import React, { useRef, useEffect } from 'react';
import LogoBlack from '../../icons/logoBlack';
import SendIcon from '../../icons/SendIcon';
import LoadingChat from '../../icons/LoadingChat';

const ChatInterface = ({ messages, inputText, setInputText, sendMessage, completeInterview, loading }) => {
    const inputRef = useRef(null);  // Ref for the textarea
    const lastMessageRef = useRef(null); // Ref for the last message
    const chatMessagesRef = useRef(null); // Ref for the messages container

    // Adjust textarea height based on content
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto'; // Reset the height
            inputRef.current.style.height = inputRef.current.scrollHeight + 'px'; // Set it to the content height
        }
    }, [inputText]);  // Run this effect whenever inputText changes

    // Scroll to the last message when messages update, accounting for input height
    useEffect(() => {
        if (lastMessageRef.current && inputRef.current && chatMessagesRef.current) {
            // Get the height of the input box
            const inputHeight = inputRef.current.offsetHeight;

            // Scroll into view with offset to prevent hiding behind the input box
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight - inputHeight,
                behavior: 'smooth'
            });
        }
    }, [messages, loading]);  // Run this effect whenever messages or loading state updates

    return (
        <div className="chat-interface container mx-auto relative flex flex-col md:w-[37rem] sm:w-full h-screen">
            <div className='w-full flex items-center justify-between p-4'>
                <LogoBlack />
                <button 
                    onClick={completeInterview} 
                    className="bg-BGGrey-DARK hover:bg-yellow-600 text-canary py-2 px-4 rounded-full">
                    Finish
                </button>
            </div>

            {/* Messages container */}
            <div 
                ref={chatMessagesRef} // Ref for scrolling
                className="chat-messages flex-1 overflow-y-auto space-y-4 p-4"
            >
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.sender === 'bot' ? 'bg-chat-botBG text-black clear-both mt-4 relative float-left block max-w-80 ' : 'bg-chat-userBG text-black float-right clear-both relative block mb-4 max-w-80 '} p-4 rounded-md`}
                    >
                        {message.text}
                    </div>
                ))}
                
                {loading && (
                    <div className="loading-indicator flex justify-left mt-4 clear-both w-full pt-4">
                        <LoadingChat /> {/* Loading spinner or gif */}
                    </div>
                )}

                {/* Ref to ensure we scroll to the last message */}
                <div ref={lastMessageRef} />
            </div>

            {/* Input bar */}
            <div className="chat-input w-full flex p-4 md:shadow-2xl bg-white md:rounded-full sm:rounded-none md:mb-12 sticky bottom-0">
                <textarea
                    ref={inputRef}
                    placeholder="Type a message..."
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    className="flex-grow bg-BGGrey rounded-full py-1.5 pl-7 pr-20 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 resize-none overflow-hidden"
                    disabled={loading} // Disable input while loading
                    rows={1}
                    style={{ minHeight: '3rem', maxHeight: '10rem' }}
                />
                <button
                    onClick={() => sendMessage(inputText)}
                    className="block relative pt-3 pr-4 pl-5 pb-8 items-center float-right ml-4 bg-canary hover:bg-yellow-600 h-4 text-black rounded-full"
                    disabled={loading} // Disable button while loading
                >
                    <SendIcon />
                </button>
            </div>
        </div>
    );
};

export default ChatInterface;
