import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserInformationForm = ({ onSubmit }) => {
    const [ageRange, setAgeRange] = useState('');
    const [occupation, setOccupation] = useState('');
    const [location, setLocation] = useState('');
    const [townCity, setTownCity] = useState('');
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryNames = response.data.map(country => country.name.common).sort();
            setCountries(countryNames);
        };
        fetchCountries();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ ageRange, occupation, location, townCity });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 p-6 mt-16 bg-white rounded-lg shadow-md w-full mx-auto">
            {/* Age Range Field */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Age Range:</label>
                <select
                    value={ageRange}
                    onChange={(e) => setAgeRange(e.target.value)}
                    required
                    className="mt-1 block w-full px-4 py-4 bg-BGGrey p-4 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="">Select your age range</option>
                    <option value="18-24">18-24</option>
                    <option value="25-34">25-34</option>
                    <option value="35-50">35-50</option>
                    <option value="50-65">50-65</option>
                    <option value="65+">65+</option>
                </select>
            </div>

            {/* Occupation Field */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Occupation:</label>
                <input
                    type="text"
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    required
                    className="mt-1 block w-full  px-2 py-2  bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>

            {/* Location Field */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Location:</label>
                <select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                    className="mt-1 block w-full  px-4 py-4  bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="">Select your country</option>
                    {countries.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                    ))}
                </select>
            </div>

            {/* Town/City Field */}
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Town/City:</label>
                <input
                    type="text"
                    value={townCity}
                    onChange={(e) => setTownCity(e.target.value)}
                    required
                    className="mt-1 block w-full  px-2 py-2  bg-BGGrey rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full flex bg-BGGrey-DARK justify-center mt-8 px-4 py-4  border border-transparent rounded-full shadow-sm text-sm font-medium text-canary bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Start interview
            </button>
        </form>
    );
};

export default UserInformationForm;
