import React from 'react';

const FinalScreen = () => (
    <div>
        <h2>Thank you for completing the interview!</h2>
        <p>You will be redirected to the home page shortly.</p>
    </div>
);

export default FinalScreen;
